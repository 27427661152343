<template>
  <cs-page-user class="views-user-orderDetails">
    <cs-panel-default title="订单详情" @back="backClick">
      <div class="wait-pay" v-if="formModel.orderStatus == 1">
        <cs-icon
          name="icon-dingdanxiangqing_daifukuan"
          style="font-size: 24px; color: #c58335; margin-top: -5px"
        />
        <div>
          <b>待付款</b>
          <p>
            应付金额：<label>¥{{ formModel.receivableAmount }}</label>
          </p>
          <button @click="payClick">去支付</button>
        </div>
      </div>
      <cs-panel title="订单信息">
        <div class="order-info">
          <a-descriptions layout="vertical" :column="4" :colon="false">
            <a-descriptions-item label="订单编号">
              {{ formModel.orderNumber }}
            </a-descriptions-item>
            <a-descriptions-item label="企业名称">
              {{ formModel.companyName }}
            </a-descriptions-item>
            <a-descriptions-item label="下单用户">
              {{ formModel.orderUserRealName }}&emsp;&emsp;{{
                formModel.orderUserPhone
              }}
            </a-descriptions-item>
            <a-descriptions-item label="下单时间">
              {{ formModel.createDate }}
            </a-descriptions-item>
          </a-descriptions>
        </div>
      </cs-panel>
      <cs-panel title="支付详情">
        <div class="pay-info">
          <a-descriptions layout="vertical" :column="4" :colon="false">
            <a-descriptions-item label="订单状态">
              {{ stateOrderStatus.name(formModel.orderStatus) }}
            </a-descriptions-item>
            <a-descriptions-item label="支付方式">
              {{ statePayType.name(formModel.payType) | defaultText }}
            </a-descriptions-item>
            <a-descriptions-item label="订单原价">
              <cs-icon name="icon-qian" size="14" />
              {{ formModel.orderAmount }}
            </a-descriptions-item>
            <a-descriptions-item label="优惠金额">
              <cs-icon name="icon-qian" size="14" />
              {{ formModel.discountAmount }}
            </a-descriptions-item>
            <a-descriptions-item label="应付金额">
              <cs-icon name="icon-qian" size="14" />
              {{ formModel.receivableAmount }}
              <br />
              <br />
              <a-statistic
                class="price"
                title="实付金额"
                :value="formModel.actualAmount | defaultText"
                :precision="2"
                :value-style="{ color: '#FF5620', 'font-weight': 'bold' }"
                style="margin-left: 10px; margin-top: -10px"
              >
                <template #prefix>
                  <cs-icon style="color: #ff5620" name="icon-qian" size="14" />
                </template>
              </a-statistic>
            </a-descriptions-item>
          </a-descriptions>
        </div>
      </cs-panel>

      <cs-panel title="产品明细">
        <cs-table
          header-cell-class-name="cs-table-header-cell"
          :resizable="false"
          :stripe="true"
          size="medium"
          align="center"
          ref="table"
          border="none"
          :load="loadData"
          :height="null"
          :config="{ pagination: { hide: true } }"
          style="width: 900px"
        >
          <vxe-table-column
            field="productName"
            title="产品名称"
          ></vxe-table-column>
          <vxe-table-column field="specsInfo" title="规格"></vxe-table-column>
          <vxe-table-column
            field="specsActualPrice"
            title="原价 (元)"
          ></vxe-table-column>
          <vxe-table-column field="starLevel" title="评价星级">
            <template v-slot="{ row }">
              {{ stateStarState.name(row.starLevel) }}
            </template>
          </vxe-table-column>
          <vxe-table-column field="content" title="评价内容"></vxe-table-column>
        </cs-table>
      </cs-panel>
    </cs-panel-default>
  </cs-page-user>
</template>

<script>
import {
  stateOrderStatus,
  statePayType,
  stateStarState,
} from "@/config/state/index.js";

export default {
  data() {
    return {
      formModel: {
        id: "",
        orderNumber: "",
        companyName: "",
        orderUserRealName: "",
        orderUserPhone: "",
        createDate: "",
        orderStatus: "",
        payType: "",
        orderAmount: 0,
        discountAmount: 0,
        receivableAmount: 0,
        actualAmount: 0,
      },
      stateOrderStatus,
      statePayType,
      stateStarState,
    };
  },
  created() {
    const { id } = this.$route.query;
    if (!id) {
      return this.$router.replace("/user/order");
    }
    this.formModel.id = id;
    // 判断是否移动端打开
    if (this.api.validate.isMobileOS()) {
      return location.replace(`/portal-wap/#/user/orderDetails?id=${id}`);
    }
  },
  methods: {
    backClick(){
      this.$router.replace('/user/order')
    },
    payClick() {
      const { id } = this.formModel;
      this.$router.push({
        path: "/product/payOrder",
        query: {
          id,
        },
      });
    },
    async loadData() {
      const { id } = this.formModel;
      // 企业信息 详情查询
      const res = await this.api.service.fpc_oapi_order_selectOrderById(
        {},
        { id }
      );
      const { code, data, msg } = res;
      // 如果没有登录，则跳转
      if (code == 1) {
        return this.$router.push({
          path: "/login",
          query: { redirectUrl: this.$route.fullPath },
        });
      }
      await this.api.toast({ code, msg }, 0);
      this.formModel.orderNumber = data.orderNumber;
      this.formModel.companyName = data.companyName;
      this.formModel.orderUserRealName = data.orderUserRealName;
      this.formModel.orderUserPhone = data.orderUserPhone;
      this.formModel.createDate = data.createDate;
      this.formModel.orderStatus = data.orderStatus;
      this.formModel.payType = data.payType;
      let { orderAmount, actualAmount, discountAmount, receivableAmount } =
        data;
      if (!this.api.validate.isEmpty(orderAmount)) {
        this.formModel.orderAmount = orderAmount.toFixed(2);
      }
      if (!this.api.validate.isEmpty(actualAmount)) {
        this.formModel.actualAmount = actualAmount.toFixed(2);
      }
      if (!this.api.validate.isEmpty(discountAmount)) {
        this.formModel.discountAmount = discountAmount.toFixed(2);
      }
      if (!this.api.validate.isEmpty(receivableAmount)) {
        this.formModel.receivableAmount = receivableAmount.toFixed(2);
      }
      return {
        data: data.products.map((ele) => {
          let arr = [];
          if (ele.serviceTime) {
            arr.push(`${ele.serviceTime}个月`);
          }
          if (ele.storeNum) {
            arr.push(`${ele.storeNum}个门店`);
          }
          if (ele.duramax) {
            arr.push(`${ele.duramax}次数`);
          }
          ele.specsInfo = arr.join("，");
          return ele;
        }),
      };
    },
  },
};
</script>

<style lang="less" scoped>
.views-user-orderDetails {
  /deep/ .main-body {
    min-height: 800px !important;
    height: auto !important;
  }
  /deep/ .cs-panel-content {
    padding: 15px 30px 50px 30px;
  }

  .wait-pay {
    background: #fffefd;
    border: 1px solid #ffd9b7;
    border-radius: 8px;
    padding: 15px 20px;
    width: 900px;
    display: flex;
    div {
      padding-left: 15px;
    }
    b {
      color: #7c480b;
      font-size: 16px;
    }
    p {
      color: #7c480b;
      margin-top: 5px;
    }
    label {
      color: #ff5620;
    }
    button {
      border: none;
      cursor: pointer;
      width: 100px;
      height: 36px;
      background-image: linear-gradient(90deg, #fdedd5 19%, #eecc97 100%);
    }
  }

  .order-info,
  .pay-info {
    border: 1px solid #dbdbdb;
    border-radius: 8px;
    width: 900px;
    padding: 40px 30px 30px 30px;
  }
  .pay-info {
    background: url(../../../public/img/pay-detail-bg.png) no-repeat right
      bottom;
    background-size: 200px;
    .price {
      display: flex;
      align-items: flex-end;
      margin-left: 0 !important;
      /deep/ .ant-statistic-title {
        color: #7d7d7d;
        padding-right: 10px;
      }
    }
  }
  .order-info {
    background: url(../../../public/img/order-detail-bg.png) no-repeat right
      center;
    background-size: 200px;
  }

  /deep/ .ant-descriptions-item {
    .ant-descriptions-item-label {
      color: #7d7d7d;
    }
    .ant-descriptions-item-content {
      color: #333333;
    }
  }
}
</style>