import State from '@zhululu/finance/src/extend/state'

export const stateMenuType = new State({
    目录: 0,
    菜单: 1,
    权限: 2
})

// 订单状态 除了已付款都可以付款
export const stateOrderStatus = new State({
    待付款: 1,
    已付款: 2,
    已取消: 3,
})

// 支付方式
export const statePayType = new State({
    支付宝: 1,
    微信: 2,
    企业网银: 3,
    线下转账: 4,
})

//评价星级
export const stateStarState = new State({
    五星: 5,
    四星: 4,
    三星: 3,
    二星: 2,
    一星: 1
})